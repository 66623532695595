<template>
	<div>
		<div v-if="userInfo">
			<b-button
				variant="primary"
				@click.prevent="goToRoute('ClientDashboardHome')"
				:disabled="isLoading"
			>
				Dashboard
			</b-button>
		</div>
		<div v-else>
			<b-button
				variant="primary"
				@click.prevent="goToRoute('login')"
				:disabled="isLoading"
			>
				Login
			</b-button>
			<b-button
				class="ml-2"
				variant="success"
				@click.prevent="goToRoute('SignUp')"
				:disabled="isLoading"
			>
				Sign Up
			</b-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AuthButtons',

	data() {
		return {
			isLoading: false,
		}
	},

	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
	},

	methods: {
		goToRoute(name) {
			this.isLoading = true;
			this.$router.push({ name });
		},
	},
}
</script>