<template>
	<div class="row">
		<div
			id="page-header-container"
			class="col-sm-12"
		>
			<div class="page-title-box">
				<h4 class="font-size-18">
					{{ title }}
				</h4>
			</div>
			<div v-if="buttonText && buttonEvent">
				<div
					v-b-tooltip.hover="{ customClass: 'tooltip' }"
					:title="tooltip"
				>
					<b-button
						:id="buttonId"
						type="submit"
						variant="primary"
						class="w-md"
						:class="buttonClasses"
						:disabled="isDisabled"
						@click="$emit(buttonEvent)"
					>
						<i
							v-if="'iconClasses'"
							:class="iconClasses"
						/>
						{{ buttonText }}
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * Page-header component
 */
export default {
	components: {},
	props: {
		title: {
			type: String,
			default: ""
		},
		buttonId: {
			type: String,
			default: null,
			required: false,
		},
		buttonText: {
			type: String,
			default: null,
		},
		buttonEvent: {
			type: String,
			default: null,
		},
		buttonClasses: {
			type: String,
			default: '',
		},
		iconClasses: {
			type: String,
			default: null,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: String,
			default: null,
		}
	}
};
</script>

<style lang="scss" scoped>
#page-header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

i {
	margin-right: 4px;
}

.tooltip {
	cursor: default;
}
</style>
