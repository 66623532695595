import { render, staticRenderFns } from "./privacy-notice.vue?vue&type=template&id=0061a51c&scoped=true&"
import script from "./privacy-notice.vue?vue&type=script&lang=js&"
export * from "./privacy-notice.vue?vue&type=script&lang=js&"
import style0 from "./privacy-notice.vue?vue&type=style&index=0&id=0061a51c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0061a51c",
  null
  
)

export default component.exports