<template>
	<div id="layout-wrapper">
		<HorizontalTopbar
			:isPublicPage="true"
		/>
		<div class="main-content">
			<div class="page-content">
				<div class="container-fluid">
					<slot />
				</div>
			</div>
			<Footer />
		</div>
	</div>
</template>

<script>
import { layoutComputed } from "@/state/helpers";

import HorizontalTopbar from "@/components/horizontal-topbar";
import Footer from "@/components/footer";

/**
 * Horizontal-layout component
 */
export default {
	components: { HorizontalTopbar, Footer },
	computed: {
		...layoutComputed
	},
	created: () => {
		document.body.setAttribute("data-layout", "horizontal");
		document.body.setAttribute("data-topbar", "dark");
		document.body.removeAttribute("data-sidebar");
		document.body.classList.remove("vertical-collpsed");
		document.body.removeAttribute('data-layout-size');
		document.body.removeAttribute('data-sidebar-size');
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo', false);
	},
};
</script>