<template>
	<div>
		<b-button
			v-if="userInfo && userInfo.subscription && !userInfo.subscription.isActive && !userInfo.subscription.isEnded && !userInfo.subscription.isCanceled && !userInfo.subscription.isOnTrial"
			variant="danger"
			@click.prevent="goToPricingPage"
			:disabled="isLoading"
		>
			Trial expired! Click here to subscribe.
		</b-button>
		<b-button
			v-if="userInfo && userInfo.subscription && userInfo.subscription.isEnded"
			variant="danger"
			@click.prevent="goToStripePortal"
			:disabled="isLoading"
		>
			Subscription expired! Click here to renew.
		</b-button>
	</div>
</template>

<script>
export default {
	name: 'AccountStatusIndicator',

	data() {
		return {
			isLoading: false,
		}
	},

	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
	},

	methods: {
		goToPricingPage() {
			this.isLoading = true;
			this.$router.push({ name: 'ClientPricing' });
		},

		async goToStripePortal() {
			this.isLoading = true;
			const url = await BillingService.getStripePortalUrl();
			window.location = url;
		}
	},
}
</script>