<template>
	<header id="page-topbar">
		<div class="navbar-header">
			<div class="d-flex">
				<!-- LOGO -->
				<div class="navbar-brand-box">
					<a @click.prevent="goToHomePage" class="logo logo-dark">
						<span class="logo-sm">
							<img src="@/assets/images/logo-sm.png" alt height="22" />
						</span>
						<span class="logo-lg">
							<img src="@/assets/images/logo-dark.png" alt height="40" />
						</span>
					</a>

					<a @click.prevent="goToHomePage" class="logo logo-light">
						<span class="logo-sm">
							<img src="@/assets/images/logo-sm.png" alt height="22" />
						</span>
						<span class="logo-lg">
							<img src="@/assets/images/logo-light.png" alt height="40" />
						</span>
					</a>
				</div>

				<button
					v-if="!isPublicPage"
					type="button"
					class="btn btn-sm mr-2 font-size-24 d-lg-none header-item"
					@click="toggleMenu"
				>
					<i class="mdi mdi-menu"></i>
				</button>
			</div>

			<div class="d-flex mt-4 mb-4">
				<AccountStatusIndicator 
					v-if="!isPricingPage && !isPublicPage"
				/>
				<AuthButtons
					v-if="isPublicPage"
				/>
			</div>
			
		</div>
	</header>
</template>

<script>
import AccountStatusIndicator from '@/components/client-dashboard/account-status-indicator.vue';
import AuthButtons from '@/components/public/auth-buttons.vue';

export default {
	components: {
		AccountStatusIndicator,
		AuthButtons,
	},

	props: {
		isPublicPage: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	computed: {
		isPricingPage() {
			return this.$route.name === 'ClientPricing';
		}
	},

	methods: {
		goToHomePage() {
			if (this.$router.currentRoute.fullPath !== '/') this.$router.push('/');
		},

		toggleMenu() {
			let element = document.getElementById("topnav-menu-content");
			element.classList.toggle("show");
		}
	}
};
</script>
