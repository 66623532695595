<template>
  <Layout>
    <PageHeader
			:title="'Privacy Notice'"
		/>
		<PrivacyNotice />
  </Layout>
</template>

<script>
import Layout from "../../layouts/public";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PrivacyNotice from '@/components/privacy-notice';

export default {
	name: 'Privacy',
  page: {
    title: "Privacy",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
		Layout, 
		PageHeader,
		PrivacyNotice,	
	},
};
</script>